import logo from './logo.svg';
import './App.css';


function App() {
  return (
    <>
    <div>
     <nav className="nav">
        <div className="container">
            <div className="logo">
            <a class="brand" href="index.html"><img src="logo-default-296x52.png" alt="" width="256"/></a>
            </div>
           
            <div id="mainListDiv" className="main_list">
                <ul className="navlinks">
                    <li><a href="#">Insergo</a></li>
                    <li><a href="#">Courses</a></li>
                    <li><a href="#">Services</a></li>
                    <li><a href="#">Login</a></li>
                </ul>
            </div>
            <span className="navTrigger">
                <i></i>
                <i></i>
                <i></i>
            </span>
        </div>
    </nav>
    </div> 
    <section className="home">
    <div>
        <img className ="home1"src="home1-03-1920x950.jpg" />
    </div>
        <div className='mytext'>
            We are upgrading login support and Certificate Support to add in LinkedIn<br/> 
            Will be back soon, till the time <br/>download the app to be in touch
            <br/><br/>
            <a href="/Insergo_v_2.9.apk" className="btn btn-white btn-animate"> Download the app</a>
        </div>
    <div>
        <a  href=""><img  className="gst" src="foreground-02-1600x60.png"/></a>
    </div>
    </section>


    </>
  );
}

export default App;
